import React from "react"

const CaptionEMGComfort = () => (
    <div className="captionEMGComfort">
        <h1>LifeSync<br />EMG Needles</h1>
        <p>Signal quality you can trust, comfort your patients will love.</p>
    </div>
)

export default CaptionEMGComfort
