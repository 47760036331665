import React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import LayoutEMGComfort from "../components/EMGCOmfort/layoutEMGComfort"
import SEO from "../components/seo"

import IconEMGNeedles from '../images/icon-emg-needles.png'

import LPForm from "../components/lpform";

const EMGComfortPage = () => {
  return (
    <LayoutEMGComfort>
      <SEO 
        title="LifeSync EMG Needles | Get a Free Sample" 
        description="Come for the signal quality. Stay for the comfort. Get a free sample to see for yourself how a LifeSync EMG needle can aid your practice."
        ogimg="https://www.lifesync.com/static/hero-home-p-68887cab881dc915bb49debf1d67f892.jpg"
        link="https://www.lifesync.com/emgcomfort/"
      />
      <Container className="lpEMGComfort">
          <Row>
              <Col xs={12} md={{order:2, span: 5}} lg={{order:2, span: 4}}>
                <LPForm />
              </Col>
              <Col xs={12} md={{order:1, span: 7}} lg={{order:1, span: 8}} className="bodyContent">
                <h2>Come for the signal quality. Stay for the comfort.</h2>

                <p>Welcome to a clearer baseline and a more comfortable experience for your patient and for you. See for yourself how a LifeSync EMG needle can aid your practice:</p>

                <ul>
                  <li>Experience a clearer signal: LifeSync coatings are designed to decrease the risk of noise by exposing only the needle tip.</li>
                  <li>Deliver precise therapy, whether large or small muscle groups: Choose from a range of needle lengths and gauge—even up to 30 ga.</li>
                  <li>Needle tip geometry affects penetration force, a factor in pain perception. LifeSync offers conical and trocar tips, designed for patient comfort.</li>
                  <li>Grip designed for clinical comfort in repositioning maneuvers.</li>
                </ul>

                <p>&nbsp;</p>

                <Row>
                  <Col xs={12} lg={4} className="icon-wrapper">
                    <img src={IconEMGNeedles} alt="LifeSync EMG Needles" className="icon" />
                  </Col>
                  <Col xs={12} lg={8} className="icon-title">
                    <h3>LifeSync EMG Needles for all your diagnosis and therapy needs</h3>
                  </Col>
                </Row>

                <p><br />LifeSync is a leading supplier of EMG needles ranging from 25 mm to 75 mm in length and a wide gauge variety.<br /><br /></p>

                <p className="bold blue">Register now for a free EMG Needle sample.</p>

                <Button className="btn btn-primary" onClick={() => scrollTo('#freeSampleForm')}>Select your needle here</Button>
              </Col>
          </Row>
      </Container>
    </LayoutEMGComfort>
  );
};

export default EMGComfortPage
