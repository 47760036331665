import React from "react"
import * as Yup from "yup"
import * as axiosjs from "axios"
import Button from "react-bootstrap/Button"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

// RegEx for phone number validation
// const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const validateEmail = async value => {
  console.log(value);
  if (!value) {
    return false
  } else {
    const valid = await emailSchema.isValid({ email: value })
    console.log(valid);
    if (valid) {
      const validate = await axiosjs.post(
        "https://lifesync.meritmile.com/api/email-validate",
        {
          email: value,
        }
      )
      return validate.data.deliverable
    } else {
      return false
    }
  }
}

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
})

// Yup.addMethod('email', "validate", validateEmail);


// onSubmit={async (values, { setSubmitting, resetForm }) => {
//   // When button submits form and form is in the process of submitting, submit button is disabled
//   setSubmitting(true)
//   const axios = axiosjs.create({
//     headers: {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//   })
//   await axios.post("http://localhost:3000/api/customers", values) // https://lifesync.meritmile.com
//   setSubmitting(false)
//   navigate("/thank-you")
//   // Simulate submitting to database, shows us values submitted, resets form
// }}

const onSubmit = async data => {
  // console.log('on submit')

  const axios = axiosjs.create({
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
  await axios.post("https://lifesync.meritmile.com/api/customers", data)

  navigate("/thank-you/")
}

const LPForm = () => {
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  return (
    <div id="freeSampleForm" className="form-wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <h3>
            Register for a free LifeSync
            <br />
            EMG Needle sample.
          </h3>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="notes">* Needle Type</label>
          <select
            name="notes"
            className="form-control"
            ref={register({ required: true })}
          >
            <option>Choose One...</option>
            <option value="016380 - Monopolar (built-in cable) 29 ga, 37 mm">
              Monopolar (built-in cable) 29 ga, 37 mm
            </option>
            <option value="016383 - Monopolar (built-in cable) 28 ga, 37 mm">
              Monopolar (built-in cable) 28 ga, 37 mm
            </option>
            <option value="TEAD3735008 - Monopolar detachable 28 ga, 37 mm">
              Monopolar detachable 28 ga, 37 mm
            </option>
            <option value="853025 - Concentric 30 ga, 25 mm">
              Concentric 30 ga, 25 mm
            </option>
            <option value="983025 - Injectable 30 ga, 25 mm">
              Injectable 30 ga, 25 mm
            </option>
            <option value="982737 - Injectable 27 ga, 37 mm">
              Injectable 27 ga, 37 mm
            </option>
          </select>
          {errors.notes && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="first_name">* First Name</label>
          <input
            type="text"
            name="first_name"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 100 })}
          />
          {errors.first_name && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="last_name">* Last Name</label>
          <input
            type="text"
            name="last_name"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 100 })}
          />
          {errors.last_name && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="company">* Company</label>
          <input
            type="text"
            name="company"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 160 })}
          />
          {errors.company && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="email">* Email</label>
          <input
            type="email"
            name="email"
            placeholder=""
            ref={register({ required: true, validate: validateEmail})}
            className="form-control"
          />
          {errors.email && <div className="error-message">A valid email is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="phone">* Phone</label>
          <input
            type="text"
            name="phone"
            placeholder=""
            className="form-control"
            ref={register({ required: true })}
          />
          {errors.phone && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="address1">* Address</label>
          <input
            type="text"
            name="address1"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 100 })}
          />
          {errors.address1 && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="address2">Address 2</label>
          <input
            type="text"
            name="address2"
            placeholder=""
            className="form-control"
          />
        </div>
        <div className="row">
          <div className="col-6 padding-r-0">
            <div className="form-group">
              <label className="form-label" htmlFor="city">* City</label>
              <input
                type="text"
                name="city"
                placeholder=""
                className="form-control"
                ref={register({ required: true, minLength: 2, maxLength: 100 })}
              />
              {errors.city && <div className="error-message">This field is required</div>}
            </div>
          </div>
          <div className="col-6 padding-l-0">
            <div className="form-group">
              <label className="form-label" htmlFor="state_or_province">* State</label>
              <select
                name="state_or_province"
                className="form-control"
                ref={register({ required: true })}
              >
                <option value="">Choose One...</option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District Of Columbia">
                  District Of Columbia
                </option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakot">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
              </select>
              {errors.state_or_province && <div className="error-message">This field is required</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 padding-r-0">
            <div className="form-group">
              <label className="form-label" htmlFor="postal_code">* Postal Code</label>
              <input
                type="text"
                name="postal_code"
                placeholder=""
                className="form-control"
                ref={register({ required: true, minLength: 4, maxLength: 15 })}
              />
              {errors.postal_code && <div className="error-message">This field is required</div>}
            </div>
          </div>
          <div className="col-6 padding-l-0">
            <div className="form-group">
              <label className="form-label" htmlFor="country_code">Country (US only)</label>
              <select
                as="select"
                name="country_code"
                className="form-control"
                ref={register({ required: true })}
              >
                <option value="US">United States</option>
              </select>
              {errors.country_code && <div className="error-message">This field is required</div>}
            </div>
          </div>
        </div>
        <Button variant="primary" type="submit">
          Get My sample
        </Button>
        <p className="smaller">
          By clicking Submit, you agree to the Terms of Service and Privacy
          Policy
        </p>
      </form>
    </div>
  )
}

export default LPForm
