import React from "react"
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Logo from '../../images/lifesync-logo-white.svg'
import Hero from '../../images/hero-emgcomfort-needles.png'

import CaptionEMGComfort from "./captionEMGComfort"

const HeaderEMGComfort = ({ siteTitleContact }) => (
  <header className="headerEMGComfort">
    <Container>
      <Row className="boxedNav">
        <Col xs={6}>
          <Link to="/">
            <img src={Logo} alt="LifeSync" width="185" className="logo" title="LifeSync" loading="lazy" />
          </Link>
        </Col>
        <Col xs={6} className="text-right">
          <a href="tel:18003582468" className="topRightPhoneBoxed">CALL US AT 1-800-358-2468</a>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ order: 2, span: 7}}>
          <CaptionEMGComfort />
        </Col>
        <Col xs={12} md={{ order: 1, span: 5}} className="text-center">
          <img src={Hero} alt="EMGComfort" className="heroImage" />
        </Col>
      </Row>
    </Container>
  </header>
)

export default HeaderEMGComfort
